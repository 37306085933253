import React from "react";
import './footer.css';

const Footer = (props) => {
    return (
        <div className="footer">
            <div className="text">
                <span>P</span><span>o</span><span>w</span><span>e</span><span>r</span><span>e</span><span>d</span> <span>b</span><span>y</span> <a href="https://i-web.be/" target="_blank"><span>I</span><span>-</span><span>W</span><span>e</span><span>b</span></a>
            </div>
        </div>
    )
}
export default Footer;