import React, { useContext, useState, useEffect } from 'react';
import $ from 'jquery';
import { OrderContext } from '../../contexts/OrderContext';
import { useParams, Link } from 'react-router-dom';
import { ScrollDown, SuccessIcon1, SuccessIcon2 } from '../Svgs/Svgs';
import './Success.css';
import Footer from '../Footer/Footer';
const Success = (props) => {
    const { activeOrder } = useContext(OrderContext);
    useEffect(() => {
        $('.scroll-mouse').on('click', function () {
            $(window).scrollTop($(window).innerHeight());
        });
        $('.main-container .discover-button').on('click', function (e) {
            e.preventDefault();
            const myTimeout = setTimeout(function(){
                window.location.href="https://i-web.be/";
            }, 1800);
        });
    }, []);
    window.scrollTo(0, 0);
    return (
        <section className="main-section menu-section success-section">
            <div className="main-container main-container-success">
                <div className="success-icon1"><SuccessIcon1 /></div>

                <div className="success-text1">
                    Votre commande arrive à table.
                    <br />
                    Veuillez ne pas changer de table, comme la commande est liée à cette table précise.
                </div>

                <div className="success-icon2"><SuccessIcon2 /></div>
                <ScrollDown />
                {/* <div className="success-text2">Elle arrive!</div> */}

                <div className="success-button">
                    <Link to={'/'} className="buy-button">
                        <div className="buy-button-text">D´accord</div>
                    </Link></div>
                <Link to={'#'} className="buy-button discover-button">
                    <div class="text">
                        <span>D</span><span>e</span><span>c</span><span>o</span><span>u</span><span>v</span><span>r</span>
                        <span>e</span><span>z</span> <span>n</span><span>o</span><span>t</span><span>r</span><span>e</span> <span>a</span><span>g</span><span>e</span><span>n</span><span>c</span><span>e</span> <span>p</span><span>a</span><span>r</span><span>t</span><span>e</span><span>n</span><span>a</span><span>i</span><span>r</span><span>e</span> <a href="https://i-web.be/" target="_blank"><span>I</span><span>-</span><span>W</span><span>e</span><span>b.
                        </span></a>
                    </div>
                    {/* <div className="buy-button-text">Decouvrez notre agence partenaire</div> */}
                </Link>
            </div>
            <Footer />
        </section>
    )
}
export default Success;