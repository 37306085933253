import React, { useContext, useState } from 'react';
import { OrderContext } from '../../contexts/OrderContext';
import { useParams, Link } from 'react-router-dom';
import { SuccessIcon1, SuccessIcon2 } from '../Svgs/Svgs';
import './printfailed.css';
const PrintFailed = (props) => {
    const { activeOrder, setActiveOrder } = useContext(OrderContext);

    window.scrollTo(0, 0);
    return (
        <section className="main-section menu-section print-failed-section">
            <div className="main-container main-container-success">
                <div className="success-icon1"><SuccessIcon1 /></div>

                <div className="success-text1">Print failed message</div>

                <div className="success-button">
                    <Link to={`/`} className="buy-button">
                        <div className="buy-button-text">D´accord</div>
                    </Link></div>

            </div>
        </section>
    )
}
export default PrintFailed;