import React, { useState, useContext, useEffect } from "react";
import { useGeolocated } from "react-geolocated";
import { useParams } from "react-router-dom";
import { OrderContext } from "../../contexts/OrderContext";
import axios from "axios";
import './getposition.css';
import Loading from "../Loading/Loading";
const GetPosition = () => {
    let { activeOrder, handleChange } = useContext(OrderContext);
    const { code } = useParams();
    let [isLoading, setIsLoading] = useState(true);
    let [isEnabled, setIsEnabled] = useState(false);
    const clearCacheData = () => {
        caches.keys().then((names) => {
            names.forEach((name) => {
                caches.delete(name);
            });
        });
    };

    const { coords, isGeolocationAvailable, isGeolocationEnabled } =
        useGeolocated({
            positionOptions: {
                enableHighAccuracy: true,
                maximumAge: 0,
                timeout: Infinity,
            },
            userDecisionTimeout: null,
            geolocationProvider: navigator.geolocation,
            suppressLocationOnMount: false,
            isOptimisticGeolocationEnabled: false,
            onSuccess: (position) => {
                let finalObj = {
                    code: code,
                    latitude: position.coords.latitude,
                    longitude: position.coords.longitude
                };
                axios.interceptors.response.use(undefined, (err) => {
                    const { config, message } = err;
                    if (!config || !config.retry) {
                        return Promise.reject(err);
                    }
                    // retry while Network timeout or Network Error
                    if (!(message.includes("timeout") || message.includes("Network Error"))) {
                        return Promise.reject(err);
                    }
                    config.retry -= 1;
                    const delayRetryRequest = new Promise((resolve) => {
                        setTimeout(() => {
                            console.log("retry the request", config.url);
                            resolve();
                        }, config.retryDelay || 1000);
                    });
                    return delayRetryRequest.then(() => axios(config));
                });
                axios.post('https://i-restaurant.i-web.be/api/set-location', finalObj, { retry: 5, retryDelay: 60000 }).then(res => {
                    if (res.data.success && res.data.order === 1) {
                        localStorage.clear();
                        let temp = { ...activeOrder, active: true, code: code, orders: [], latitude: finalObj.latitude, longitude: finalObj.longitude, expire: new Date().getTime() + 1800000 };
                        handleChange(temp);
                        setIsLoading(false);
                        window.location.href = `/`;
                    } else {
                        localStorage.clear();
                        let temp = { ...activeOrder, active: false, code: code, orders: [], latitude: finalObj.latitude, longitude: finalObj.longitude, expire: new Date().getTime() + 1800000 };
                        handleChange(temp);
                        setIsLoading(false);
                        window.location.href = `/`;
                    }
                }).catch(err => {
                    setIsLoading(false);
                    //localStorage.clear();
                    // window.location.href = `/`;
                });
            }
        });
    useEffect(() => {
        clearCacheData();
        localStorage.clear();
        if (!isGeolocationEnabled) {
            setIsLoading(false);
            setIsEnabled(false);
        }
    }, []);

    return (
        <section className="main-section position-section">
            {(isEnabled && isLoading) && <Loading />}
            <div className="message">
                Veuillez autoriser l'accès pour obtenir votre position
            </div>
            <button className="main-btn" onClick={() => { window.location.reload() }}>Réessayer</button>
        </section >
    )
};

export default GetPosition;