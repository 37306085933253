import React, { useContext, useState, useEffect } from 'react';
import { OrderContext } from '../../contexts/OrderContext';
import { useParams, useLocation } from 'react-router-dom';
import axios from 'axios';
import { Bottle, Glass } from '../Svgs/Svgs';
import OrderBtn from '../OrderBtn/OrderBtn';
import Header from '../Header/Header';
import MenuElement from '../MenuElement/MenuElement';
import './Details.css';
import { Cart } from '../Svgs/Svgs';
import { Link } from 'react-router-dom';
import $ from 'jquery';
const Details = (props) => {
    const { activeOrder } = useContext(OrderContext);
    const checkUrl = useLocation().state;
    useEffect(() => {
        if (checkUrl === null) {
            window.location.href = '/directaccess';
        }
    }, []);

    // const [mainElems, setMainElems] = useState(false);
    const [targetElem, setTargetElem] = useState([{
        id: 0,
        name: '',
        vins: []
    }]);
    const [element, setElement] = useState([]);
    // const { id, element_id, sub_id } = useParams();
    const { id, mainElemId, subId, name, elem, wine, menuName } = checkUrl !== null && checkUrl;
    const [order, setOrder] = useState(true);
    window.scrollTo(0, 0);
    const glass = elem.products.filter(elem1 => {
        return parseInt(elem1.pivot.type) === 1;
    });
    const bottle = elem.products.filter(elem1 => {
        return parseInt(elem1.pivot.type) === 0;
    });
    return (

        checkUrl !== null && <section className="main-section menu-section element-menu-section">
            <Header title={name.toUpperCase()} target={`/menu/${id}`} state={{}} />
            <div className="main-container main-container-details">
                {
                    <React.Fragment>
                        {
                            elem.title ? (
                                <div className="sub-element-name" style={{marginBottom:(!elem.description || elem.description==='')?'30px':'12px'}}>
                                    {elem.title}
                                </div>
                            ) : ''
                        }
                        {
                            elem.description ? (
                                <div className="sub-element-description">
                                    {elem.description}
                                </div>
                            ) : ''
                        }
                        <div className="svgs-side">
                            <Link to={glass.length > 0 ? `/glass/${id}/${mainElemId}/${subId}` : '#'} className={glass.length > 0 ? 'glass' : 'glass hide'} state={{
                                id: id, mainElemId: mainElemId, subId: subId, name: name, elem: elem, price: (glass.length > 0) ? (
                                    glass[0].vatin1
                                ) : '0'
                                , wine: wine, menuName: menuName
                            }}>
                                <div className="svg-area">
                                    <Glass />
                                </div>
                                {
                                    glass.length > 0 && (
                                        <div className='number-area'>
                                            {

                                                glass[0].vatin1

                                            }
                                        </div>
                                    )
                                }

                            </Link>
                            <Link to={bottle.length > 0 ? `/bottle/${id}/${mainElemId}/${subId}` : '#'} className={bottle.length > 0 ? 'bottle' : 'bottle hide'} state={{ id: id, mainElemId: mainElemId, subId: subId, name: name, elem: elem, price: bottle.length > 0 ? bottle[0].vatin1 : 0, wine: wine, menuName: menuName }}>
                                <div className="svg-area">
                                    <Bottle />
                                </div>
                                {bottle.length > 0 && (<div className='number-area'>
                                    {bottle[0].vatin1}
                                </div>)}
                            </Link>
                        </div>

                    </React.Fragment>

                }
            </div>
            {activeOrder.active && <OrderBtn />}
        </section>
    )
}
export default Details;