import React, { useState, useEffect, useContext } from 'react';
import { useLocation } from 'react-router-dom';
import './concept.css';
import Header from '../Header/Header';
import ImgDetails from '../ImgDetails/ImgDetails';
import { OrderContext } from '../../contexts/OrderContext';
import Footer from '../Footer/Footer';
import Parser from 'html-react-parser';
const Concept = (props) => {
    const { activeOrder } = useContext(OrderContext);
    const [content, setContent] = useState(`Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
    
    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
    
    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.`);
    const checkUrl = useLocation().state;
    const data = useLocation().state.data[0];
    //const desc = DOMParser().parseFromString(data.description, "text/xml");
    useEffect(() => {
        if (checkUrl === null) {
            window.location.href = '/directaccess';
        }
    }, []);
    window.scrollTo(0, 0);
    return (
        checkUrl !== null && <section className="main-section concept-section">
            <div className="main-container">
                <Header title='Notre Concept' target={'/'} />
                <ImgDetails img={data.image} content={Parser(data.description)} />
            </div>
            <Footer />
        </section>
    )
}

export default Concept;