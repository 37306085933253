import { BrowserRouter, Routes, Route } from "react-router-dom";
import Accueil from "./components/Accueil/Accueil";
import Concept from "./components/Concept/Concept";
import Producteurs from "./components/Producteurs/Producteurs";
import Carte from "./components/Carte/Carte";
import Events from "./components/Events/Events";
import Menu from "./components/Menu/Menu";
import Details from "./components/Details/Details";
import Glass from "./components/Glass/Glass";
import Bottle from "./components/Bottle/Bottle";
import BottleEdit from "./components/BottleEdit/BottleEdit";
import MenuServices from "./components/MenuServices/MenuServices";
import OrderContextProvider from "./contexts/OrderContext";
import Person from './components/Person/Person';
import Buy from "./components/Buy/Buy";
import YourOrder from "./components/YourOrder/YourOrder";
import Success from "./components/Success/Success";
import GlassEdit from "./components/GlassEdit/GlassEdit";
import MenuEdit from "./components/MenuEdit/MenuEdit";
import PrintFailed from "./components/PrintFailed/PrintFailed";
import GetPosition from "./components/GetPosition/GetPosition";
import DirectAccess from "./components/DirectAccess/DirectAccess";
import FinalSave from "./components/FinalSave/FinalSave";
import CheckCode from "./components/CheckCode/CheckCode";
import Save from "./components/Save/Save";
import Order from "./components/Order/Order";
function App() {
  return (
    <BrowserRouter>
      <div className="App">
        <main>
          <Routes>
            <Route exact path="/" element={<OrderContextProvider><Accueil /></OrderContextProvider>} />
            <Route exact path="/code=:code" element={<OrderContextProvider><CheckCode /></OrderContextProvider>} />
            <Route exact path="/checkposition/code=:code" element={<OrderContextProvider><GetPosition /></OrderContextProvider>} />
            <Route path="/concept" element={<OrderContextProvider><Concept /></OrderContextProvider>} />
            <Route path="/events" element={<OrderContextProvider><Events /></OrderContextProvider>} />
            <Route path="/producteurs" element={<OrderContextProvider><Producteurs /></OrderContextProvider>} />
            <Route path="/producteur/:firstName" element={<OrderContextProvider><Person /></OrderContextProvider>} />
            <Route path="/cart" element={<OrderContextProvider><Carte /></OrderContextProvider>} />
            <Route path="/menu/:id" element={<OrderContextProvider><Menu /></OrderContextProvider>} />
            <Route path="/element/:id/:element_id/:sub_id" exact element={<OrderContextProvider><Details /></OrderContextProvider>} />
            <Route path="/yourOrder" exact element={<OrderContextProvider><YourOrder /></OrderContextProvider>} />
            <Route path="/order" exact element={<OrderContextProvider><Order /></OrderContextProvider>} />
            <Route path="/glass/:id/:element_id/:sub_id" exact element={<OrderContextProvider><Glass /></OrderContextProvider>} />
            <Route path="/bottle/:id/:element_id/:sub_id" exact element={<OrderContextProvider><Bottle /></OrderContextProvider>} />
            <Route path="/bottle/edit/:id/:element_id/:sub_id" exact element={<OrderContextProvider><BottleEdit /></OrderContextProvider>} />
            <Route path="/glass/edit/:id/:element_id/:sub_id" exact element={<OrderContextProvider><GlassEdit /></OrderContextProvider>} />
            <Route path="/menuedit" exact element={<OrderContextProvider><MenuEdit /></OrderContextProvider>} />
            <Route path="/menuService/:id/:menu_id" exact element={<OrderContextProvider><MenuServices /></OrderContextProvider>} />
            <Route path="/success" exact element={<OrderContextProvider><Success /></OrderContextProvider>} />
            <Route path="/printfailed" exact element={<OrderContextProvider><PrintFailed /></OrderContextProvider>} />
            <Route path="/buy/:rootId/:mainId/:subId" element={<OrderContextProvider><Buy /></OrderContextProvider>} />
            <Route path="/directaccess" element={<OrderContextProvider><DirectAccess /></OrderContextProvider>} />
            <Route path="/finalsave" element={<OrderContextProvider><FinalSave /></OrderContextProvider>} />
            <Route path="/save" element={<OrderContextProvider><Save /></OrderContextProvider>} />
            <Route path="*" element={<OrderContextProvider ativate={false}><Accueil /></OrderContextProvider>} />
          </Routes>
        </main>
      </div>
    </BrowserRouter>
  );
}

export default App;
