import React, { useState, useEffect, useContext } from "react";
import { Link, useParams } from "react-router-dom";
import axios from "axios";
import './accueil.css';
import Img from '../../images/main.jpg';
import Logo from '../../images/logo.svg';
import { OrderContext } from "../../contexts/OrderContext";
import Footer from "../Footer/Footer";
import Loading from "../Loading/Loading";
const Accueil = (props) => {
    let { activeOrder, handleChange } = useContext(OrderContext);
    console.log(activeOrder);
    //let { code } = useParams();
    const [img, setImg] = useState(Img);
    const [loaded, setLoaded] = useState(false);
    const [buttons, setButtons] = useState([
        {
            id: 0,
            content: 'Notre Concept',
            target: '/concept',
            data: []
        },
        {
            id: 1,
            content: 'Nos Producteurs',
            target: '/producteurs',
            data: []
        },
        /*  {
              id: 2,
              content: 'Nos évènements',
              target: '/events'
          },*/
        {
            id: 2,
            content: 'Notre Carte',
            target: '/cart',
            data: ['']
        },
        {
            id: 3,
            content: 'Nos Événements',
            target: '/events',
            data: []
        }
    ]);
    let [concept, setConcept] = useState({
        id: 0,
        data: []
    });
    let [producteurs, setProducteurs] = useState({
        id: 1,
        data: []
    });
    let [cart, setCart] = useState({
        id: 2,
        data: []
    });
    let [events, setEvents] = useState({
        id: 3,
        data: []
    });
    useEffect(() => {
        axios.interceptors.response.use(undefined, (err) => {
            const { config, message } = err;
            if (!config || !config.retry) {
                return Promise.reject(err);
            }
            // retry while Network timeout or Network Error
            if (!(message.includes("timeout") || message.includes("Network Error"))) {
                return Promise.reject(err);
            }
            config.retry -= 1;
            const delayRetryRequest = new Promise((resolve) => {
                setTimeout(() => {
                    console.log("retry the request", config.url);
                    resolve();
                }, config.retryDelay || 1000);
            });
            return delayRetryRequest.then(() => axios(config));
        });
        try {
            axios.get('https://i-restaurant.i-web.be/api/get-data', { retry: 5, retryDelay: 60000 }).then(res => {
                let tempButtons = [...buttons];
                let conc = res.data.data.filter(item => {
                    return item.hasOwnProperty("concept");
                });
                if (conc.length > 0) {
                    conc = conc[0].concept;
                    tempButtons[0].data.push(conc);
                }
                let produc = res.data.data.filter(item => {
                    return item.hasOwnProperty("producteurs");
                });
                if (produc.length > 0) {
                    produc = produc[0].producteurs;
                }
                let events = res.data.data.filter(item => {
                    return item.hasOwnProperty("events");
                });
                if (events.length > 0) {
                    events = events[0].events;
                }
                tempButtons[1].data = produc;
                tempButtons[3].data = events;
                setButtons([...tempButtons]);
                setLoaded(true);
            });
        }
        catch (err) {
            console.log(err);
        }
    }, []);
    // let getConcept = async () => {
    //     try {
    //         let d = await axios.get('https://i-restaurant.i-web.be/api/get-concept').then(res => {
    //             let tempBtns = [...buttons];
    //             tempBtns[0].data = res.data.data;
    //             setButtons([...tempBtns]);
    //         });
    //     }
    //     catch (err) {
    //         console.log(err);
    //     }
    // };
    // let getProducteurs = async () => {
    //     try {
    //         let d = await axios.get('https://i-restaurant.i-web.be/api/get-producteurs').then(res => {
    //             let tempBtns = [...buttons];
    //             tempBtns[1].data = res.data.data;
    //             setButtons([...tempBtns]);
    //         });
    //     }
    //     catch (err) {
    //         console.log(err);
    //     }
    // };
    // let getEvents = async () => {
    //     try {
    //         let d = await axios.get('https://i-restaurant.i-web.be/api/get-events').then(res => {
    //             let tempBtns = [...buttons];
    //             tempBtns[3].data = res.data.data;
    //             setButtons([...tempBtns]);
    //         });
    //     }
    //     catch (err) {
    //         console.log(err);
    //     }
    // };
    // useEffect(() => {

    //     getProducteurs();
    //     getConcept();
    //     getEvents();
    // });
    // useEffect(() => {
    //     if (activeOrder.active === null) {
    //         let temp = {
    //             ...activeOrder
    //         };
    //         temp = {
    //             ...temp,
    //             code: code,
    //             active: props.activate
    //         };
    //         handleChange({ ...temp });
    //     }
    // }, []);
    window.scrollTo(0, 0);
    return (

        <section className="main-section accueil-section" style={{ backgroundImage: `url(${Img})` }}>
            {!loaded && <Loading></Loading>}
            <div className="main-content">
                <div className="main-container">
                    <div className="main-header">
                        <span>I-restaurant Test Version</span>
                    </div>
                    <div className="buttons">
                        {
                            buttons.map(btn => {

                                if (btn.data.length > 0) {
                                    return (
                                        <Link to={btn.target} className="main-btn" key={btn.id} state={{ data: btn.data }}>{btn.content}</Link>
                                    )
                                }

                            })
                        }
                    </div>
                </div>
            </div>
            <Footer />
        </section>
    )
}
export default Accueil;