import React, { useState, useEffect } from "react";
import $ from 'jquery';
import './buy.css';
import axios from "axios";
import { useParams } from "react-router-dom";
import Header from '../Header/Header';

import Img from '../../images/element-2.jpg';

const Buy = (props) => {
    const [mainElems, setMainElems] = useState([{
        id: 0,
        root: "NOS VINS",
        description: ``,
        img: "images/element-1.jpg",
        elems: [
            {
                id: 0,
                name: "Mousseux",
                isWine: true,
                subElems: [
                    {
                        id: 0,
                        name: "Ry d'Argent, Cuvée Confluence",
                        description: "",
                        price: {
                            one: 10,
                            more: 50
                        },
                        orderNum: 0,
                        stock: 12,
                        canOrder: true
                    },
                    {
                        id: 1,
                        name: "Château Castigno, Rosé Nature",
                        description: "",
                        price: {
                            one: 10,
                            more: 46
                        },
                        orderNum: 0,
                        stock: 17,
                        canOrder: true
                    },
                    {
                        id: 2,
                        name: "Domaine Cuvelier, BizJoe",
                        description: "",
                        price: {
                            one: 12,
                            more: 55
                        },
                        orderNum: 0,
                        stock: 4,
                        canOrder: false
                    },
                    {
                        id: 3,
                        name: "Domaine Chenoy, Perles de Wallonie Blanc",
                        description: "",
                        price: {
                            one: 0,
                            more: 46
                        },
                        orderNum: 0,
                        stock: 30,
                        canOrder: true
                    }
                ]
            },
            {
                id: 1,
                name: "Blanc",
                isWine: true,
                subElems: [
                    {
                        id: 0,
                        name: "Château Castigno, Grâce des Anges",
                        description: "",
                        price: {
                            one: 6,
                            more: 28
                        },
                        orderNum: 0,
                        stock: 20,
                        canOrder: true
                    },
                    {
                        id: 1,
                        name: "Castelforte, Garganega",
                        description: "",
                        price: {
                            one: 0,
                            more: 30
                        },
                        orderNum: 0,
                        stock: 2,
                        canOrder: true
                    },
                    {
                        id: 2,
                        name: "Castelforte, Pinot Grigio",
                        description: "",
                        price: {
                            one: 6,
                            more: 30
                        },
                        orderNum: 0,
                        stock: 6,
                        canOrder: true
                    },
                    {
                        id: 3,
                        name: "Meerestein, Chenin-Pinot Grigio",
                        description: "",
                        price: {
                            one: 0,
                            more: 32
                        },
                        orderNum: 0,
                        stock: 6,
                        canOrder: true
                    },
                    {
                        id: 4,
                        name: "Pierre Henri, Chardonnay",
                        description: "",
                        price: {
                            one: 7,
                            more: 32
                        },
                        orderNum: 0,
                        stock: 6,
                        canOrder: true
                    },
                    {
                        id: 5,
                        name: "Costières de Pomerols, Le Jade Viognier",
                        description: "",
                        price: {
                            one: 0,
                            more: 32
                        },
                        orderNum: 0,
                        stock: 6,
                        canOrder: true
                    },
                    {
                        id: 6,
                        name: "Ry d'Argent, Bouisson",
                        description: "",
                        price: {
                            one: 7,
                            more: 32
                        },
                        orderNum: 0,
                        stock: 6,
                        canOrder: true
                    },
                    {
                        id: 7,
                        name: "Vin de Liège, Eolides",
                        description: "",
                        price: {
                            one: 0,
                            more: 34
                        },
                        orderNum: 0,
                        stock: 6,
                        canOrder: true
                    },
                    {
                        id: 8,
                        name: "Ferme du Chapitre, Brabançonne",
                        description: "",
                        price: {
                            one: 7,
                            more: 34
                        },
                        orderNum: 0,
                        stock: 6,
                        canOrder: true
                    },
                    {
                        id: 9,
                        name: "Château de Bioul, Terre Charlot",
                        description: "",
                        price: {
                            one: 8,
                            more: 36
                        },
                        orderNum: 0,
                        stock: 6,
                        canOrder: true
                    },
                    {
                        id: 10,
                        name: "Famille De Boel, Saint-Joseph",
                        description: "",
                        price: {
                            one: 0,
                            more: 55
                        },
                        orderNum: 0,
                        stock: 6,
                        canOrder: true
                    },
                    {
                        id: 11,
                        name: "Domaine Cuvelier, Bizonder Chardonnay",
                        description: "",
                        price: {
                            one: 0,
                            more: 36
                        },
                        orderNum: 0,
                        stock: 6,
                        canOrder: true
                    },
                    {
                        id: 12,
                        name: "Domaine Cuvelier, BizTro",
                        description: "",
                        price: {
                            one: 0,
                            more: 38
                        },
                        orderNum: 0,
                        stock: 6,
                        canOrder: true
                    },
                    {
                        id: 13,
                        name: "Château Fosse Sèche, Arcane",
                        description: "",
                        price: {
                            one: 0,
                            more: 55
                        },
                        orderNum: 0,
                        stock: 6,
                        canOrder: true
                    },
                    {
                        id: 3,
                        name: "Château Castigno, Grâce des Anges Magnum",
                        description: "",
                        price: {
                            one: 0,
                            more: 56
                        },
                        orderNum: 0,
                        stock: 6,
                        canOrder: true
                    }
                ]
            },
            {
                id: 2,
                name: "ROUGE",
                isWine: true,
                subElems: [
                    {
                        id: 0,
                        name: "Château Castigno, Grâce des Anges",
                        description: "",
                        price: {
                            one: 0,
                            more: 28
                        },
                        orderNum: 0,
                        stock: 5,
                        canOrder: false
                    },
                    {
                        id: 1,
                        name: "Château Castigno, Terra Casta",
                        description: "",
                        price: {
                            one: 7,
                            more: 32
                        },
                        orderNum: 0,
                        stock: 6,
                        canOrder: true
                    },
                    {
                        id: 2,
                        name: "Terradoce, Primitovo",
                        description: "",
                        price: {
                            one: 6,
                            more: 30
                        },
                        orderNum: 0,
                        stock: 0,
                        canOrder: true
                    },
                    {
                        id: 3,
                        name: "Domaine Colombette, Cabernet Noir Sans Sulfites",
                        description: "",
                        price: {
                            one: 0,
                            more: 30
                        },
                        orderNum: 0,
                        stock: 9,
                        canOrder: true
                    },
                    {
                        id: 4,
                        name: "Jean-Michel Dupré, Beaujolais Vignes de 1940",
                        description: "",
                        price: {
                            one: 7,
                            more: 33
                        },
                        orderNum: 0,
                        stock: 6,
                        canOrder: true
                    },
                    {
                        id: 5,
                        name: "Domaine Chenoy, Terra Nova",
                        description: "",
                        price: {
                            one: 0,
                            more: 34
                        },
                        orderNum: 0,
                        stock: 6,
                        canOrder: true
                    },
                    {
                        id: 6,
                        name: "Constant-Duquesnoy, Lou Combaou",
                        description: "",
                        price: {
                            one: 7,
                            more: 34
                        },
                        orderNum: 0,
                        stock: 6,
                        canOrder: true
                    },
                    {
                        id: 7,
                        name: "Vin de Liège, Eben",
                        description: "",
                        price: {
                            one: 9,
                            more: 40
                        },
                        orderNum: 0,
                        stock: 6,
                        canOrder: true
                    },
                    {
                        id: 8,
                        name: "Ferme du Chapitre, Saint Rémi",
                        description: "",
                        price: {
                            one: 0,
                            more: 34
                        },
                        orderNum: 0,
                        stock: 6,
                        canOrder: true
                    },
                    {
                        id: 9,
                        name: "Famille De Boel, Assiégés",
                        description: "",
                        price: {
                            one: 8,
                            more: 38
                        },
                        orderNum: 0,
                        stock: 6,
                        canOrder: true
                    },
                    {
                        id: 10,
                        name: "Domaine Mas d'Albo, Or brun",
                        description: "",
                        price: {
                            one: 0,
                            more: 42
                        },
                        orderNum: 0,
                        stock: 6,
                        canOrder: true
                    },
                    {
                        id: 11,
                        name: "Domaine Cuvelier, BizNoir",
                        description: "",
                        price: {
                            one: 0,
                            more: 50
                        },
                        orderNum: 0,
                        stock: 6,
                        canOrder: true
                    },
                    {
                        id: 12,
                        name: "Château Fosse Sèche, Eolithe",
                        description: "",
                        price: {
                            one: 0,
                            more: 53
                        },
                        orderNum: 0,
                        stock: 6,
                        canOrder: true
                    }
                ]
            }
        ]
    },
    {
        id: 1,
        root: "BOISSONS",
        description: ``,
        img: "images/element-2.jpg",
        elems: [
            {
                id: 0,
                name: "Apéritifs",
                isWine: false,
                subElems: [
                    {
                        id: 0,
                        name: "Apéritif Maison",
                        description: "",
                        price: [9],
                        orderNum: 0,
                        stock: 12,
                        canOrder: true
                    },
                    {
                        id: 1,
                        name: "1836 Organic Gin & Tonic",
                        description: "",
                        price: [12],
                        orderNum: 0,
                        stock: 17,
                        canOrder: true
                    },
                    {
                        id: 2,
                        name: "1836 Organic Gin Barrel Aged & Tonic",
                        description: "",
                        price: [12],
                        orderNum: 0,
                        stock: 4,
                        canOrder: false
                    },
                    {
                        id: 3,
                        name: "The Blackbird's Gin & Tonic (Fraise de Wépion)",
                        description: "",
                        price: [12],
                        orderNum: 0,
                        stock: 30,
                        canOrder: true
                    },
                    {
                        id: 4,
                        name: "Terra Nova Gin de Namur & Tonic (Poire Conférence)",
                        description: "",
                        price: [12],
                        orderNum: 0,
                        stock: 30,
                        canOrder: true
                    },
                    {
                        id: 5,
                        name: "Kir Vin fruits rouges ou verveine",
                        description: "",
                        price: [8],
                        orderNum: 0,
                        stock: 30,
                        canOrder: true
                    },
                    {
                        id: 6,
                        name: "Kir Bulles fruits rouges ou verveine",
                        description: "",
                        price: [11],
                        orderNum: 0,
                        stock: 30,
                        canOrder: true
                    },
                    {
                        id: 7,
                        name: "Vermouth Maître Pierre blanc ou rouge",
                        description: "",
                        price: [7],
                        orderNum: 0,
                        stock: 30,
                        canOrder: true
                    }
                ]
            },
            {
                id: 1,
                name: "BIÈRES AU FUT",
                isWine: false,
                subElems: [
                    {
                        id: 0,
                        name: "Bière du NID 33cl",
                        description: "",
                        price: [3.50],
                        orderNum: 0,
                        stock: 12,
                        canOrder: true
                    },
                    {
                        id: 1,
                        name: "Houppe 33cl",
                        description: "",
                        price: [4],
                        orderNum: 0,
                        stock: 17,
                        canOrder: true
                    },
                    {
                        id: 2,
                        name: "Philomène Florale 33cl",
                        description: "",
                        price: [4],
                        orderNum: 0,
                        stock: 4,
                        canOrder: false
                    },
                    {
                        id: 3,
                        name: "Bertinchamps Blanche 25cl",
                        description: "",
                        price: [3.50],
                        orderNum: 0,
                        stock: 30,
                        canOrder: true
                    }
                ]
            },
            {
                id: 2,
                name: "AUTRES BIÈRES",
                isWine: false,
                subElems: [
                    {
                        id: 0,
                        name: "Houppe Jambes en l'air",
                        description: "",
                        price: [4.50],
                        orderNum: 0,
                        stock: 12,
                        canOrder: true
                    },
                    {
                        id: 1,
                        name: "Philomène Hoptimale",
                        description: "",
                        price: [4.50],
                        orderNum: 0,
                        stock: 17,
                        canOrder: true
                    },
                    {
                        id: 2,
                        name: "Bertinchamps Triple 50cl",
                        description: "",
                        price: [6],
                        orderNum: 0,
                        stock: 4,
                        canOrder: false
                    },
                    {
                        id: 3,
                        name: "Orval",
                        description: "",
                        price: [5],
                        orderNum: 0,
                        stock: 30,
                        canOrder: true
                    },
                    {
                        id: 4,
                        name: "CharlesRoy 350",
                        description: "",
                        price: [4.50],
                        orderNum: 0,
                        stock: 30,
                        canOrder: true
                    },
                    {
                        id: 5,
                        name: "CharlesRoy Bancloque",
                        description: "",
                        price: [4.50],
                        orderNum: 0,
                        stock: 30,
                        canOrder: true
                    },
                    {
                        id: 6,
                        name: "CharlesRoy IPA",
                        description: "",
                        price: [4.50],
                        orderNum: 0,
                        stock: 30,
                        canOrder: true
                    },
                    {
                        id: 7,
                        name: "CharlesRoy Cherry",
                        description: "",
                        price: [4.50],
                        orderNum: 0,
                        stock: 30,
                        canOrder: true
                    }
                ]
            },
            {
                id: 3,
                name: "SOFTS",
                isWine: false,
                subElems: [
                    {
                        id: 0,
                        name: "BRU plate/pétillante 25cl",
                        description: "",
                        price: [2.50],
                        orderNum: 0,
                        stock: 12,
                        canOrder: true
                    },
                    {
                        id: 1,
                        name: "BRU plate/pétillante 50cl",
                        description: "",
                        price: [4.50],
                        orderNum: 0,
                        stock: 17,
                        canOrder: true
                    },
                    {
                        id: 2,
                        name: "BRU plate/pétillante 100cl",
                        description: "",
                        price: [7],
                        orderNum: 0,
                        stock: 4,
                        canOrder: false
                    },
                    {
                        id: 3,
                        name: "Spa orange/citron 20cl",
                        description: "",
                        price: [2.80],
                        orderNum: 0,
                        stock: 30,
                        canOrder: true
                    },
                    {
                        id: 4,
                        name: "Tonic Val Dieu 20cl",
                        description: "",
                        price: [3],
                        orderNum: 0,
                        stock: 30,
                        canOrder: true
                    },
                    {
                        id: 5,
                        name: "Jus pomme 25cl",
                        description: "",
                        price: [3.20],
                        orderNum: 0,
                        stock: 30,
                        canOrder: true
                    },
                    {
                        id: 6,
                        name: "Jus pomme/fraise 25cl",
                        description: "",
                        price: [3.20],
                        orderNum: 0,
                        stock: 30,
                        canOrder: true
                    },
                    {
                        id: 7,
                        name: "Jus pomme/cerise 25cl",
                        description: "",
                        price: [3.20],
                        orderNum: 0,
                        stock: 30,
                        canOrder: true
                    },
                    {
                        id: 8,
                        name: "Jus pomme/poire 25cl",
                        description: "",
                        price: [3.20],
                        orderNum: 0,
                        stock: 30,
                        canOrder: true
                    },
                    {
                        id: 9,
                        name: "Ritchie Cola 27.5cl",
                        description: "",
                        price: [3.50],
                        orderNum: 0,
                        stock: 30,
                        canOrder: true
                    },
                    {
                        id: 10,
                        name: "Ritchie Cola Zero 27.5cl",
                        description: "",
                        price: [3.50],
                        orderNum: 0,
                        stock: 30,
                        canOrder: true
                    },
                    {
                        id: 11,
                        name: "Ritchie Orange 27.5cl",
                        description: "",
                        price: [3.50],
                        orderNum: 0,
                        stock: 30,
                        canOrder: true
                    },
                    {
                        id: 12,
                        name: "Ritchie Citron 27.5cl",
                        description: "",
                        price: [3.50],
                        orderNum: 0,
                        stock: 30,
                        canOrder: true
                    },
                    {
                        id: 13,
                        name: "Ritchie Grapefruit 27.5cl",
                        description: "",
                        price: [3.50],
                        orderNum: 0,
                        stock: 30,
                        canOrder: true
                    }
                ]
            },
            {
                id: 4,
                name: "DIGESTIFS",
                isWine: false,
                subElems: [
                    {
                        id: 0,
                        name: "Irish/Italian Coffee",
                        description: "",
                        price: [9],
                        orderNum: 0,
                        stock: 12,
                        canOrder: true
                    },
                    {
                        id: 1,
                        name: "1836 Rhum",
                        description: "",
                        price: [8],
                        orderNum: 0,
                        stock: 17,
                        canOrder: true
                    },
                    {
                        id: 2,
                        name: "Mora Mora Liqueur de Rhum",
                        description: "",
                        price: [9],
                        orderNum: 0,
                        stock: 4,
                        canOrder: false
                    },
                    {
                        id: 3,
                        name: "Lambertus Whiskey 10 ans",
                        description: "",
                        price: [9],
                        orderNum: 0,
                        stock: 30,
                        canOrder: true
                    },
                    {
                        id: 4,
                        name: "Lambertus Whiskey Single Cask",
                        description: "",
                        price: [9],
                        orderNum: 0,
                        stock: 30,
                        canOrder: true
                    },
                    {
                        id: 5,
                        name: "Lambertus Whiskey Single Malt",
                        description: "",
                        price: [9],
                        orderNum: 0,
                        stock: 30,
                        canOrder: true
                    },
                    {
                        id: 6,
                        name: "Limoncello",
                        description: "",
                        price: [7],
                        orderNum: 0,
                        stock: 30,
                        canOrder: true
                    },
                    {
                        id: 7,
                        name: "Amaretto",
                        description: "",
                        price: [7],
                        orderNum: 0,
                        stock: 30,
                        canOrder: true
                    },
                    {
                        id: 8,
                        name: "Falcon PoireCognac Liqueur",
                        description: "",
                        price: [7],
                        orderNum: 0,
                        stock: 30,
                        canOrder: true
                    },
                    {
                        id: 9,
                        name: "1836 Vodka",
                        description: "",
                        price: [7],
                        orderNum: 0,
                        stock: 30,
                        canOrder: true
                    },
                    {
                        id: 10,
                        name: "Cafés",
                        description: "",
                        price: [3.50],
                        orderNum: 0,
                        stock: 30,
                        canOrder: true
                    },
                    {
                        id: 11,
                        name: "Thé",
                        description: "",
                        price: [3.60],
                        orderNum: 0,
                        stock: 30,
                        canOrder: true
                    },
                    {
                        id: 12,
                        name: "Chocolat Chaud, Latte",
                        description: "",
                        price: [4.50],
                        orderNum: 0,
                        stock: 30,
                        canOrder: true
                    }
                ]
            }
        ]
    },
    {
        id: 2,
        root: "BRASSERIE REVISITÉE",
        description: ``,
        img: "images/element-1.jpg",
        elems: [
            {
                id: 0,
                name: "A PARTAGER",
                isWine: false,
                subElems: [
                    {
                        id: 0,
                        name: "Planche de charcuteries et fromage belge",
                        description: "accompagnée de sa tapenade à la betterave rouge",
                        price: [15],
                        orderNum: 0,
                        stock: 12,
                        canOrder: true
                    },
                    {
                        id: 1,
                        name: "Nos frites maison et leur sauce",
                        description: "",
                        price: [7],
                        orderNum: 0,
                        stock: 17,
                        canOrder: true
                    }
                ]
            },
            {
                id: 1,
                name: "LES CLASSIQUES",
                isWine: false,
                subElems: [
                    {
                        id: 0,
                        name: "Avisance à la viande de boulettes kimchi de betterave, yaourt à l'ail",
                        description: "Vin : Château Castigno, Terra Casta - 7.00",
                        price: [13],
                        orderNum: 0,
                        stock: 20,
                        canOrder: true
                    },
                    {
                        id: 1,
                        name: "Poireaux à la vinaigrette à l'asiatique, guanciale - Entrée",
                        description: "Vin : Ry d'Argent, Bouisson - 7.00",
                        price: [13.00],
                        orderNum: 0,
                        stock: 2,
                        canOrder: true
                    },
                    {
                        id: 2,
                        name: "Poireaux à la vinaigrette à l'asiatique, guanciale - Plat",
                        description: "Vin : Ry d'Argent, Bouisson - 7.00",
                        price: [16.00],
                        orderNum: 0,
                        stock: 2,
                        canOrder: true
                    },
                    {
                        id: 3,
                        name: "Burger pur boeuf, cacao, chou, mayonnaise relevée",
                        description: "Vin : Constant-Duquesnoy, Lou Combaou - 7.00",
                        price: [16],
                        orderNum: 0,
                        stock: 6,
                        canOrder: true
                    },
                    {
                        id: 4,
                        name: "Boulettes, sauce liégeoise",
                        description: "Vin : Terradoce, Primitivo - 6.00",
                        price: [16],
                        orderNum: 0,
                        stock: 6,
                        canOrder: true
                    },
                    {
                        id: 5,
                        name: "Vol-au-vent",
                        description: "Vin : Castelforte, Pinot Grigio - 6.00",
                        price: [17],
                        orderNum: 0,
                        stock: 6,
                        canOrder: true
                    }
                ]
            },
            {
                id: 2,
                name: "LES PATES",
                isWine: false,
                subElems: [
                    {
                        id: 0,
                        name: "Pâtes fraîches aux oeufs, fromage bleu de brebis",
                        description: "Vin : Château de Bioul, Terre Charlot - 8.00",
                        price: [18],
                        orderNum: 0,
                        stock: 5,
                        canOrder: false
                    },
                    {
                        id: 1,
                        name: "Pâtes fraîches aux oeufs, poisson du jour, carottes, bisque",
                        description: "Vin : Pierre Henri, Chardonnay - 7.00",
                        price: [20],
                        orderNum: 0,
                        stock: 6,
                        canOrder: true
                    }
                ]
            },
            {
                id: 3,
                name: "LES VIANDES",
                isWine: false,
                subElems: [
                    {
                        id: 0,
                        name: "Poulet fermier, purée, carottes",
                        description: "Vin : Château du Loup, Corbières - 7.00",
                        price: [19],
                        orderNum: 0,
                        stock: 5,
                        canOrder: false
                    },
                    {
                        id: 1,
                        name: "Magret de canard, pommes grenailles, légumes du moment",
                        description: "Vin : Famille De Boel, Assiégés - 8.00",
                        price: [19],
                        orderNum: 0,
                        stock: 6,
                        canOrder: true
                    },
                    {
                        id: 2,
                        name: "Carré de veau, stoemp aux carottes, sauce archiduc",
                        description: "Vin : Jean-Michel Dupré, Beaujolais, Vignes de 1940 - 7.00",
                        price: [18],
                        orderNum: 0,
                        stock: 6,
                        canOrder: true
                    }
                ]
            },
            {
                id: 4,
                name: "LES POISSONS",
                isWine: false,
                subElems: [
                    {
                        id: 0,
                        name: "Saumon en gravelax, betterave, crème aigre à l'aneth - Entrée",
                        description: "Vin : Pierre Henri, Chardonnay - 7.00",
                        price: [13],
                        orderNum: 0,
                        stock: 5,
                        canOrder: false
                    },
                    {
                        id: 1,
                        name: "Saumon en gravelax, betterave, crème aigre à l'aneth - Plat",
                        description: "Vin : Pierre Henri, Chardonnay - 7.00",
                        price: [18],
                        orderNum: 0,
                        stock: 5,
                        canOrder: false
                    },
                    {
                        id: 2,
                        name: "Poisson du jour grillé, orge perlé risotto, légumes de saison",
                        description: "Vin : Vin de Liège, Les Eolides - 7.00",
                        price: [19],
                        orderNum: 0,
                        stock: 6,
                        canOrder: true
                    },
                    {
                        id: 3,
                        name: "Dos de Skreï, lentilles au curry vert, chou rave",
                        description: "Vin : Ry d'Argent, Bouisson - 7.00",
                        price: [18],
                        orderNum: 0,
                        stock: 6,
                        canOrder: true
                    }
                ]
            },
            {
                id: 5,
                name: "LES DESSERTS",
                isWine: false,
                subElems: [
                    {
                        id: 0,
                        name: "Part de tarte du jour",
                        description: "",
                        price: [7],
                        orderNum: 0,
                        stock: 5,
                        canOrder: false
                    },
                    {
                        id: 1,
                        name: "Dame blanche",
                        description: "",
                        price: [9],
                        orderNum: 0,
                        stock: 6,
                        canOrder: true
                    },
                    {
                        id: 2,
                        name: "Ile flottante, glace caramel",
                        description: "",
                        price: [10],
                        orderNum: 0,
                        stock: 6,
                        canOrder: true
                    },
                    {
                        id: 3,
                        name: "Profiteroles, glace stracciatella",
                        description: "",
                        price: [10],
                        orderNum: 0,
                        stock: 6,
                        canOrder: true
                    },
                    {
                        id: 4,
                        name: "Riz au lait, pomme fondante",
                        description: "",
                        price: [10],
                        orderNum: 0,
                        stock: 6,
                        canOrder: true
                    },
                    {
                        id: 5,
                        name: "Mille-feuille à la vanille",
                        description: "",
                        price: [10],
                        orderNum: 0,
                        stock: 6,
                        canOrder: true
                    },
                    {
                        id: 2,
                        name: "Assiette de fromages belges",
                        description: "",
                        price: [14],
                        orderNum: 0,
                        stock: 6,
                        canOrder: true
                    }
                ]
            }
        ]
    },
    {
        id: 3,
        root: "MENU BISTRONOMIQUE",
        description: `Menu 3 services : 36.00
        
        Menu 2 services : 28.00
        
        N'hésitez pas à nous avertir en cas d'obligation.
        
        Nous adapterons l'envoi à votre timing.`,
        img: "images/element-4.jpg",
        elems: [
            {
                id: 0,
                name: "Entrées",
                isWine: false,
                subElems: [
                    {
                        id: 0,
                        name: "Pak choi grillé, sardines millésimées",
                        description: "Vin : Château Castigno, Grâce des Anges - 6.00",
                        price: [],
                        orderNum: 0,
                        stock: 12,
                        canOrder: true
                    },
                    {
                        id: 1,
                        name: "Saumon en gravelax, betterave, crème aigre à l'aneth",
                        description: "Vin : Pierre Henri, Chardonnay - 7.00",
                        price: [],
                        orderNum: 0,
                        stock: 17,
                        canOrder: true
                    }
                ]
            },
            {
                id: 1,
                name: "Plats",
                isWine: false,
                subElems: [
                    {
                        id: 0,
                        name: "Dos de Skreï, lentilles au curry vert, chou rave",
                        description: "Vin : Ry d'Argent, Bouisson - 7.00",
                        price: [],
                        orderNum: 0,
                        stock: 20,
                        canOrder: true
                    },
                    {
                        id: 1,
                        name: `Carré de veau, stoemp aux carottes, sauce archiduc`,
                        description: `Vin : Jean-Michel Dupré, Beaujolais Vignes de 1940 - 7.00
                        Vin de Liège, Eben - 9.00`,
                        price: [],
                        orderNum: 0,
                        stock: 2,
                        canOrder: true
                    }
                ]
            },
            {
                id: 2,
                name: "Dessert",
                isWine: false,
                subElems: [
                    {
                        id: 0,
                        name: "Ile flottante, glace caramel",
                        description: "",
                        price: [],
                        orderNum: 0,
                        stock: 5,
                        canOrder: false
                    },
                    {
                        id: 1,
                        name: "Assiette de fromages belges",
                        description: "(en suppl.+14€ / en rempl. +4€)",
                        price: [],
                        orderNum: 0,
                        stock: 6,
                        canOrder: true
                    }
                ]
            }

        ]
    }]);
    $(function () {
        $('.buy-section .back-arrow').on('click', function () {
            window.history.back();
        });
    });
    window.scrollTo(0, 0);
    let { rootId, mainId, subId } = useParams();
    rootId = parseInt(rootId);
    mainId = parseInt(mainId);
    subId = parseInt(subId);
    let rootElem = mainElems.find(elem => elem.id === rootId);
    let mainElem = rootElem.elems.find(elem => elem.id === mainId);
    let tarElem = mainElem.subElems.find(elem => elem.id === subId);
    let increaseStock = () => {
        const rootResult = mainElems.filter(elem => elem.id === rootId);
        const mainResult = rootResult[0].elems.filter(elem => elem.id === mainId);
        const result = mainResult[0].subElems.filter(sub => sub.id === subId);
        if (result[0].stock !== 0) {
            result[0].stock -= 1;
            result[0].orderNum += 1;
        }
        let newElems = mainElems.map(elem1 => {

            if (elem1.id === rootId) {
                elem1.elems.map(elem2 => {
                    if (elem2.id === mainId) {
                        elem2.subElems.map(sub1 => {
                            if (sub1.id === result[0].id) {
                                sub1.orderNum = result[0].orderNum;
                                sub1.stock = result[0].stock;
                            }
                        })
                    }
                })
            }
            return elem1;
        })
        setMainElems(newElems);
    }
    let decreaseStock = () => {
        const rootResult = mainElems.filter(elem => elem.id === rootId);
        const mainResult = rootResult[0].elems.filter(elem => elem.id === mainId);
        const result = mainResult[0].subElems.filter(sub => sub.id === subId);
        if (result[0].orderNum !== 0) {
            result[0].stock += 1;
            result[0].orderNum -= 1;
        }
        let newElems = mainElems.map(elem1 => {

            if (elem1.id === rootId) {
                elem1.elems.map(elem2 => {
                    if (elem2.id === mainId) {
                        elem2.subElems.map(sub1 => {
                            if (sub1.id === result[0].id) {
                                sub1.orderNum = result[0].orderNum;
                                sub1.stock = result[0].stock;
                            }
                        })
                    }
                })
            }
            return elem1;
        })
        setMainElems(newElems);
    }
    return (
        <section className="buy-section main-section">
            <Header title='' target="#" className='back' />
            <div className="main-container">
                
                <div className="content">
                    <img src={Img} alt="" />
                    <div className="infos">
                        <div className="main-infos">
                            {
                                tarElem.title ? (
                                    <p className="title">
                                        {tarElem.title}
                                    </p>
                                ) : ''

                            }
                            {
                                tarElem.name ? (
                                    <p className="name">
                                        {tarElem.name}
                                    </p>
                                ) : ''
                            }
                        </div>
                        <div className="price">
                            {
                                tarElem.price.one + ' €'
                            }
                        </div>
                    </div>
                    <div className="order">
                        <button className='minus-btn' onClick={() => {
                            decreaseStock();
                        }}>-</button>
                        <span className="num">{tarElem.orderNum}</span>
                        <button className='plus-btn' onClick={() => {
                            increaseStock();
                        }}>+</button>
                        <span className="stock">{tarElem.stock}</span>
                    </div>
                </div>
            </div>
        </section>
    )
}
export default Buy;