import React, { useContext, useState, useEffect } from 'react';
import { OrderContext } from '../../contexts/OrderContext';
import { useParams, Link, useLocation } from 'react-router-dom';
import OrderBtn from '../OrderBtn/OrderBtn';
import Header from '../Header/Header';
import { EmptyGlass, Plus, Minos, EditIconPlus } from '../Svgs/Svgs';
import MenuElement from '../MenuElement/MenuElement';
import './BottleEdit.css';
import { Cart } from '../Svgs/Svgs';
import $ from 'jquery';
const Bottle = (props) => {
    let { activeOrder, handleChange } = useContext(OrderContext);
    const checkUrl = useLocation().state;
    useEffect(() => {
        if (checkUrl === null) {
            window.location.href = '/directaccess';
        }
    }, []);
    let targetOrder = checkUrl !== null && checkUrl.order;
    let copyTargetOrder = checkUrl !== null ? { ...targetOrder } : {};
    let price = checkUrl !== null && targetOrder.mainPrice;
    let [emptyGlassQuantity, setEmptyGlassQuantity] = useState(checkUrl !== null && targetOrder.glassCount);
    let [bottleQuantity, setBottleQuantity] = useState(checkUrl !== null && targetOrder.bottleCount);
    let [bottlePrice, setBottlePrice] = useState(checkUrl !== null && targetOrder.price);

    window.scrollTo(0, 0);

    let decreaseGlass = (quan) => {
        if (quan > 1) {
            quan -= 1;
            setEmptyGlassQuantity(quan);
        }
    }
    let increaseGlass = (quan) => {
        quan += 1;
        setEmptyGlassQuantity(quan);
    }
    let decreaseBottle = (quan) => {
        if (quan > 1) {
            quan -= 1;
            bottlePrice -= price;
            setBottlePrice(bottlePrice);
            setBottleQuantity(quan);
        }
    }
    let increaseBottle = (quan) => {
        quan += 1;
        bottlePrice += price;
        setBottlePrice(bottlePrice);
        setBottleQuantity(quan);
    }
    let changeElem = (glassQuan, bottleQuant, pri) => {
        let targetIndex = activeOrder.orders.findIndex(item => {
            return item.randId === targetOrder.randId;
        });
        if (targetIndex !== -1) {
            let shallowOrders = [...activeOrder.orders];
            shallowOrders[targetIndex] = {
                ...copyTargetOrder,
                glassCount: glassQuan,
                bottleCount: bottleQuant,
                price: pri
            }
            let temp = {
                ...activeOrder,
                orders: [...shallowOrders]
            }
            handleChange(temp);
            // window.location.href = '/yourOrder';
        }
    }
    let removeOrder = () => {
        let targetIndex = activeOrder.orders.findIndex(item => {
            return item.randId === targetOrder.randId;
        });
        if (targetIndex !== -1) {
            let shallowOrders = [...activeOrder.orders];
            shallowOrders[targetIndex] = {
                ...copyTargetOrder,
                removed: true
            }
            let temp = {
                ...activeOrder,
                orders: [...shallowOrders]
            }
            handleChange(temp);
            // window.location.href = '/yourOrder';
        }
    }
    return (
        checkUrl !== null && <section className="main-section menu-section">
            <Header title={targetOrder.categoryName.toUpperCase()} target={`/yourOrder`} state={{}} />
            <div className="buy-container">
                <div className="main-container-bottle">

                    <div className="sub-element-name-glass">
                        {targetOrder.elemName}
                    </div>


                    <div className="sub-element-description-glass">
                        {targetOrder.description}
                    </div>

                </div>
                <div className="quantity-area">
                    <div className="quantity-area-minos" onClick={() => activeOrder.active && decreaseGlass(emptyGlassQuantity)}> <Minos /> </div>
                    <div className="quantity-area-quantity"> {emptyGlassQuantity} </div>
                    <div className="quantity-area-plus" onClick={() => activeOrder.active && increaseGlass(emptyGlassQuantity)}> <Plus /> </div>
                    <div className="quantity-area-svg">
                        <EmptyGlass />
                    </div>
                </div>
                <div className="edit-area-for-bootle">
                    <Link to={`/yourOrder`} className='edit-icon-plus' onClick={() => activeOrder.active && removeOrder()} state={{}}>
                        <EditIconPlus />
                    </Link>
                    <div className="buy-button">
                        <div className="buy-button-minos" onClick={() => activeOrder.active && decreaseBottle(bottleQuantity)}><Minos /></div>
                        <div className="line-between"></div>
                        <Link to={'/yourOrder'} className="buy-button-text" onClick={() => activeOrder.active && changeElem(emptyGlassQuantity, bottleQuantity, bottlePrice)} state={{}}>{`Ajouter  ${bottleQuantity}`}</Link>
                        <div className="buy-button-price">(€{bottlePrice})</div>
                        <div className="line-between"></div>
                        <div className="buy-button-plus" onClick={() => activeOrder.active && increaseBottle(bottleQuantity)}><Plus /></div>
                    </div>
                </div>

            </div>
        </section >
    )
}
export default Bottle;