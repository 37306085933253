import React, { useState, useEffect, useContext } from 'react';
import { useParams, useLocation } from 'react-router-dom';
import axios from 'axios';
import './menu.css';
import { OrderContext } from '../../contexts/OrderContext';
import Header from '../Header/Header';
import MenuElement from '../MenuElement/MenuElement';
import MenuItem from '../MenuItem/MenuItem';
import ServiceItem from '../ServiceItem/ServiceItem';
import OrderBtn from '../OrderBtn/OrderBtn';
import { Bottle, Glass } from '../Svgs/Svgs';
import Loading from '../Loading/Loading';
const Menu = (props) => {
    const roots = [{
        id: 0,
        item: "Nos Vins"
    },
    {
        id: 1,
        item: "Cocktails"
    },
    {
        id: 2,
        item: "Les Miam Miam (pas en double)"
    },
    {
        id: 3,
        item: "Menu Bistronomique"
    }];
    let { activeOrder } = useContext(OrderContext);
    const checkUrl = useLocation().state;
    useEffect(() => {
        if (checkUrl === null) {
            window.location.href = '/directaccess';
        }
    }, []);
    const [mainElems, setMainElems] = useState([]);
    const [loading, setLoading] = useState(true);
    const { id } = useParams();
    
    let getVins = async () => {
        axios.interceptors.response.use(undefined, (err) => {
            const { config, message } = err;
            if (!config || !config.retry) {
                return Promise.reject(err);
            }
            // retry while Network timeout or Network Error
            if (!(message.includes("timeout") || message.includes("Network Error"))) {
                return Promise.reject(err);
            }
            config.retry -= 1;
            const delayRetryRequest = new Promise((resolve) => {
                setTimeout(() => {
                    console.log("retry the request", config.url);
                    resolve();
                }, config.retryDelay || 1000);
            });
            return delayRetryRequest.then(() => axios(config));
        });
        try {
            //const data = await axios.get('/all-vins.json').then(res => {
            const data = await axios.get('https://i-restaurant.i-web.be/api/get-data', { retry: 5, retryDelay: 60000 }).then(res => {
                let vins = res.data.data.filter(item => {
                    return item.hasOwnProperty("vins");
                });
                setMainElems([...vins[0].vins]);
            })
            setLoading(false);
        } catch (err) {
            console.log(err);
        }
    };
    let getBoissons = async () => {
        axios.interceptors.response.use(undefined, (err) => {
            const { config, message } = err;
            if (!config || !config.retry) {
                return Promise.reject(err);
            }
            // retry while Network timeout or Network Error
            if (!(message.includes("timeout") || message.includes("Network Error"))) {
                return Promise.reject(err);
            }
            config.retry -= 1;
            const delayRetryRequest = new Promise((resolve) => {
                setTimeout(() => {
                    console.log("retry the request", config.url);
                    resolve();
                }, config.retryDelay || 1000);
            });
            return delayRetryRequest.then(() => axios(config));
        });
        try {
            const data = await axios.get('https://i-restaurant.i-web.be/api/get-data', { retry: 5, retryDelay: 60000 }).then(res => {
                let boissons = res.data.data.filter(item => {
                    return item.hasOwnProperty("boissons");
                });
                setMainElems([...boissons[0].boissons]);
            })
            setLoading(false);
        } catch (err) {
            console.log(err);
        }
    };
    let getBrasserie = async () => {
        axios.interceptors.response.use(undefined, (err) => {
            const { config, message } = err;
            if (!config || !config.retry) {
                return Promise.reject(err);
            }
            // retry while Network timeout or Network Error
            if (!(message.includes("timeout") || message.includes("Network Error"))) {
                return Promise.reject(err);
            }
            config.retry -= 1;
            const delayRetryRequest = new Promise((resolve) => {
                setTimeout(() => {
                    console.log("retry the request", config.url);
                    resolve();
                }, config.retryDelay || 1000);
            });
            return delayRetryRequest.then(() => axios(config));
        });
        try {
            const data = await axios.get('https://i-restaurant.i-web.be/api/get-data', { retry: 5, retryDelay: 60000 }).then(res => {
                let brasserieRevisitees = res.data.data.filter(item => {
                    return item.hasOwnProperty("brasserie_revisitees");
                });
                setMainElems([...brasserieRevisitees[0].brasserie_revisitees]);
            })
            setLoading(false);
        } catch (err) {
            console.log(err);
        }
    };
    let getBistrono = async () => {
        axios.interceptors.response.use(undefined, (err) => {
            const { config, message } = err;
            if (!config || !config.retry) {
                return Promise.reject(err);
            }
            // retry while Network timeout or Network Error
            if (!(message.includes("timeout") || message.includes("Network Error"))) {
                return Promise.reject(err);
            }
            config.retry -= 1;
            const delayRetryRequest = new Promise((resolve) => {
                setTimeout(() => {
                    console.log("retry the request", config.url);
                    resolve();
                }, config.retryDelay || 1000);
            });
            return delayRetryRequest.then(() => axios(config));
        });
        try {
            const data = await axios.get('https://i-restaurant.i-web.be/api/get-data', { retry: 5, retryDelay: 60000 }).then(res => {
                let menuBistronomiques = res.data.data.filter(item => {
                    return item.hasOwnProperty("menu_bistronomiques");
                });
                setMainElems([...menuBistronomiques[0].menu_bistronomiques]);
            })
            setLoading(false);
        } catch (err) {
            console.log(err);
        }
    };
    useEffect(() => {
        if (parseInt(id) === -1) {

        }
        else if (parseInt(id) === 0) {
            getVins();
        }
        else if (parseInt(id) === 1) {
            getBoissons();
        }
        else if (parseInt(id) === 2) {
            getBrasserie();
        }
        else if (parseInt(id) === 3) {
            getBistrono();
        }
    }, []);

    const isWine = (parseInt(id) === 0 ? true : false);
    let menuName = roots.filter(root => root.id === parseInt(id))[0].item;

    window.scrollTo(0, 0);
    return (
        checkUrl !== null && <section className="main-section menu-section">
            <Header title={menuName} target="/cart" state={{}} />
            {loading && <Loading></Loading>}
            <div className="main-container main-container-menu">


                {
                    id !== "3" &&
                    mainElems.map(elem => {
                        return (
                            <MenuElement key={elem.id} id={id} elemId={elem.id} mainElemId={elem.id} elem={elem} isWine={isWine} menuName={menuName} />
                        )
                    })
                }
                {
                    id === "3" && mainElems.map(item => {
                        return (
                            <MenuItem key={item.id} id={id} elemId={item.id} mainElemId={item.id} elem={item} />
                        )
                    })
                }
                {/* {
                    id === "3" && <div className="main3-description">
                        {targetElem[0].description}
                    </div>
                }*/}
                <div className="services-items">
                    {
                        id === "3" && (
                            // mainElems.map(item => {
                            //     return (
                            //         <ServiceItem key={item.id} id={id} elemId={item.id} mainElemId={item.id} elem={item} order={activeOrder} increaseStock={increaseStock} decreaseStock={decreaseStock} />
                            //     )
                            // })
                            mainElems.map(arr => (
                                arr.sub_menus.length === 1) && (
                                    arr.sub_menus[0].menu_bistronomique_category.map(ele => (
                                        <ServiceItem key={ele.id} id={id} elemId={ele.id} mainElemId={ele.id} elem={ele} notLink />
                                    ))
                                )
                            ))

                    }
                </div>
            </div>
            {activeOrder.active && <OrderBtn />}

        </section>
    )
}
export default Menu;