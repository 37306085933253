import React from "react";
import './imginfos.css';
const ImgInfos = (props) => {
    return (
        
        <div className="img-infos">
            <img src={props.img} alt="" />
            {
                props.infos ? (
                    <div className="infos">
                        <span className="main-title">
                            {props.infos.main}
                        </span>
                        {
                            props.infos.sub ? <span className="sub-title">
                                {props.infos.sub}
                            </span> : ''
                        }

                    </div>
                ) : ''
            }

        </div>
    )
}
export default ImgInfos;