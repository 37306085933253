import React, { useState } from "react";
import './header.css';
import { Link } from 'react-router-dom'
import { Arrow } from "../Svgs/Svgs";
const Header = (props) => {
    const customState = props.state && props.state;
    return (
        <div className="header">
            <Link to={props.target} className="back-arrow" state={customState}>
                <Arrow className='back-arrow' />
            </Link>
            <span className="title">
                {props.title}
            </span>
        </div>
    )
}
export default Header;