import React, { useContext } from 'react';
import { OrderContext } from '../../contexts/OrderContext';
import { Bottle, Glass } from '../Svgs/Svgs';
import './ServiceItem.css';
import { Cart } from '../Svgs/Svgs';
import { Link } from 'react-router-dom';
import $ from 'jquery';
const ServiceItem = (props) => {
    const { activeOrder } = useContext(OrderContext);

    return (
        <div className="service-item">
            {
                props.elem.name ? (
                    <div className="service-item-header service-item-row" style={{ border: 'none' }}>
                        <div className="service-item-name">
                            <div className="service-item-text">{props.elem.name.toUpperCase()}</div>
                        </div>
                        {/* <div className="service-item-description">
                            {props.elem.description}
                        </div> */}
                    </div>
                ) : ''
            }
            {
                props.notLink ? props.elem.menu_bistronomique?.map(sub => {
                    return (
                        <div className="service-item-row" key={sub.id}
                            style={{ marginBottom: props.elem.description && props.elem.menu_bistronomique.indexOf(sub) === props.elem.menu_bistronomique.length - 1 ? '0px' : '18px' }}>
                            <div className="info-side">
                                <p className="name">
                                    {sub.title}
                                </p>
                            </div>
                        </div>
                    )
                })

                    :
                    props.elem.menu_bistronomique?.map(sub => {
                        return (
                            <Link to={`/item/${props.id}/${props.mainElemId}/${sub.id}`} className="service-item-row" key={sub.id}
                                style={{ marginBottom: props.elem.description && props.elem.menu_bistronomique.indexOf(sub) === props.elem.menu_bistronomique.length - 1 ? '0px' : '18px' }}>
                                <div className="info-side">
                                    <p className="name">
                                        {sub.title}
                                    </p>
                                </div>
                            </Link>
                        )
                    })
            }


            {(props.elem.description&&props.elem.description!=="''")  && (
                <p className="description">
                    {props.elem.description}
                </p>
            )
            }


        </div>
    )
}
export default ServiceItem;