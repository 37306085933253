import React, { useContext, useState, useEffect } from 'react';
import { OrderContext } from '../../contexts/OrderContext';
import { useParams, useLocation } from 'react-router-dom';
import OrderBtn from '../OrderBtn/OrderBtn';
import Header from '../Header/Header';
import { EmptyGlass, Plus, Minos } from '../Svgs/Svgs';
import MenuElement from '../MenuElement/MenuElement';
import './Glass.css';
import { Link } from 'react-router-dom';
import $ from 'jquery';
const Glass = (props) => {
    let { activeOrder, handleChange } = useContext(OrderContext);
    const checkUrl = useLocation().state;
    useEffect(() => {
        if (checkUrl === null) {
            window.location.href = '/directaccess';
        }
    }, []);
    let { id, mainElemId, subId, name, elem, price, wine, menuName } = checkUrl !== null && checkUrl;
    if (checkUrl !== null) {
        price = price ? parseFloat(price.replace(',', '.')) : 0
    };
    let [quantity, setQuantity] = useState(1);
    let [elemPrice, setElemPrice] = useState(price);

    let decreaseQuantity = (quan) => {
        if (quan > 1) {
            quan -= 1;
            elemPrice -= price;
            setQuantity(quan);
            setElemPrice(elemPrice);
        }
    }
    let increaseQuantity = (quan) => {
        quan += 1;
        elemPrice += price;
        setQuantity(quan);
        setElemPrice(elemPrice);
    }
    let addElem = (ele, quan, pri) => {
        if (activeOrder.active) {
            let temp = { ...activeOrder };
            temp = {
                ...temp,
                orders: [
                    ...temp.orders,
                    {
                        id: ele.id,
                        menuName: menuName,
                        categoryName: name,
                        elemName: ele.title,
                        description: ele.description,
                        glassCount: quan,
                        price: pri,
                        mainPrice: price,
                        removed: false,
                        randId: Math.random()
                    }
                ]
            }
            handleChange(temp);
            setQuantity(1);
            setElemPrice(price);
        }

    }
    window.scrollTo(0, 0);
    return (
        checkUrl !== null && <section className="main-section menu-section">
            <Header title={name.toUpperCase()} target={wine ? `/element/${id}/${mainElemId}/${subId}` : `/menu/${id}`} state={{
                id: id, mainElemId: mainElemId, subId: subId, name: name, elem: elem, wine: wine, menuName: menuName
            }} />
            <div className="buy-container">
                <div className="main-container-glass">
                    {
                        elem.title ? (
                            <div className="sub-element-name-glass">
                                {elem.title}
                            </div>
                        ) : ''
                    }
                    {
                        elem.description ? (
                            <div className="sub-element-description-glass">
                                {elem.description}
                            </div>
                        ) : ''
                    }
                </div>

                <div className="buy-button">
                    <div className="buy-button-minos" onClick={() => decreaseQuantity(quantity)}><Minos /></div>
                    <div className="line-between"></div>
                    <Link to={`/menu/${id}`} className="buy-button-text" onClick={() => addElem(elem, quantity, elemPrice)} state={{
                        id: id, mainElemId: mainElemId, subId: subId, name: name, elem: elem, wine: wine, menuName: menuName
                    }}>{`Ajouter ${quantity}`}</Link>
                    <div className="buy-button-price">(€{elemPrice})</div>
                    <div className="line-between"></div>
                    <div className="buy-button-plus" onClick={() => increaseQuantity(quantity)}><Plus /></div>
                </div>
            </div>
        </section>
    )
}
export default Glass;