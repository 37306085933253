import React, { useContext, useState, useEffect } from 'react';
import { OrderContext } from '../../contexts/OrderContext';
import { useParams, useLocation } from 'react-router-dom';
import OrderBtn from '../OrderBtn/OrderBtn';
import Header from '../Header/Header';
import { EmptyGlass, Plus, Minos, EditIconPlus } from '../Svgs/Svgs';
import MenuElement from '../MenuElement/MenuElement';
import './glassedit.css';
import { Link } from 'react-router-dom';
import $ from 'jquery';
const GlassEdit = (props) => {
    let { activeOrder, handleChange } = useContext(OrderContext);
    const checkUrl = useLocation().state;
    useEffect(() => {
        if (checkUrl === null) {
            window.location.href = '/directaccess';
        }
    }, []);
    let targetOrder;
    if (checkUrl !== null) {
        targetOrder = checkUrl.order;
    }
    let copyTargetOrder = checkUrl !== null ? { ...targetOrder } : {};
    let price = checkUrl !== null && targetOrder.mainPrice;
    let [quantity, setQuantity] = useState(checkUrl !== null && targetOrder.glassCount);
    let [elemPrice, setElemPrice] = useState(checkUrl !== null && targetOrder.price);

    let decreaseQuantity = (quan) => {
        if (quan > 1) {
            quan -= 1;
            elemPrice -= price;
            setQuantity(quan);
            setElemPrice(elemPrice);
        }
    }
    let increaseQuantity = (quan) => {
        quan += 1;
        elemPrice += price;
        setQuantity(quan);
        setElemPrice(elemPrice);
    }

    let changeElem = (glassQuan, pri) => {
        let targetIndex = activeOrder.orders.findIndex(item => {
            return item.randId === targetOrder.randId;
        });
        if (targetIndex !== -1) {
            let shallowOrders = [...activeOrder.orders];
            shallowOrders[targetIndex] = {
                ...copyTargetOrder,
                glassCount: glassQuan,
                price: pri
            }
            let temp = {
                ...activeOrder,
                orders: [...shallowOrders]
            }
            handleChange(temp);
            // window.location.href = '/yourOrder';
        }
    }
    let removeOrder = () => {
        let targetIndex = activeOrder.orders.findIndex(item => {
            return item.randId === targetOrder.randId;
        });
        if (targetIndex !== -1) {
            let shallowOrders = [...activeOrder.orders];
            shallowOrders[targetIndex] = {
                ...copyTargetOrder,
                removed: true
            }
            let temp = {
                ...activeOrder,
                orders: [...shallowOrders]
            }
            handleChange(temp);
            // window.location.href = '/yourOrder';
        }
    }
    window.scrollTo(0, 0);
    return (
        checkUrl !== null && <section className="main-section menu-section">
            <Header title={targetOrder.categoryName} target={`/yourOrder`} state={{}} />
            <div className="buy-container">
                <div className="main-container-glass">

                    <div className="sub-element-name-glass">
                        {targetOrder.elemName.toUpperCase()}
                    </div>

                    <div className="sub-element-description-glass">
                        {targetOrder.description}
                    </div>

                </div>
                <div className="edit-area-for-bootle">
                    <Link to={`/yourOrder`} className='edit-icon-plus' onClick={() => activeOrder.active && removeOrder()} state={{}}>
                        <EditIconPlus />
                    </Link>
                    <div className="buy-button">
                        <div className="buy-button-minos" onClick={() => activeOrder.active && decreaseQuantity(quantity)}><Minos /></div>
                        <div className="line-between"></div>
                        <Link to={'/yourOrder'} className="buy-button-text" onClick={() => activeOrder.active && changeElem(quantity, elemPrice)} state={{}}>{`Ajouter ${quantity}`}</Link>
                        <div className="buy-button-price">(€{elemPrice})</div>
                        <div className="line-between"></div>
                        <div className="buy-button-plus" onClick={() => activeOrder.active && increaseQuantity(quantity)}><Plus /></div>
                    </div>
                </div>
            </div>
        </section >
    )
}
export default GlassEdit;