import React, { useState, useEffect, useContext } from "react";
import { OrderContext } from '../../contexts/OrderContext';
import axios from "axios";
import Header from "../Header/Header";
import { useLocation, Link } from "react-router-dom";
import OrderBtn from "../OrderBtn/OrderBtn";
import { EditIconPlus } from "../Svgs/Svgs";
import './menuEdit.css';
const MenuEdit = (props) => {
    let { activeOrder, handleChange } = useContext(OrderContext);
    const checkUrl = useLocation().state;
    useEffect(() => {
        if (checkUrl === null) {
            window.location.href = '/directaccess';
        }
    }, []);
    let targetOrder;
    let price;
    if (checkUrl !== null) {
        targetOrder = checkUrl.order;
        price = targetOrder.mainPrice;
    }

    let [menuPrice, setMenuPrice] = useState(checkUrl !== null && targetOrder.price);
    let elem = checkUrl !== null && targetOrder.allMenu;
    let menuType = -1;
    if (checkUrl !== null) {
        if (targetOrder.type === 0) {
            menuType = 3;
        } else if (targetOrder.type === 1 || targetOrder.type === 2) {
            menuType = 2;
        }
    }

    const multiSelect = 'DESSERT';
    const [element, setElement] = useState(checkUrl !== null ? {
        ...elem, sub_menus: elem.sub_menus.map(menu => {
            return {
                ...menu, menu_bistronomique_category: menu.menu_bistronomique_category.map(cat => {
                    return {
                        ...cat,
                        menu_bistronomique: cat.menu_bistronomique.map(target => {
                            return {
                                ...target,
                                selected: false,
                                blocked: false
                            }
                            // var founded = false;
                            // targetOrder.items.map(item => {
                            //     if (target.id === item.id && target.title === item.name) {
                            //         founded = true;
                            //     }
                            // })
                            // if (founded) {
                            //     founded = false;
                            //     return {
                            //         ...target,
                            //         selected: true,
                            //         blocked: false
                            //     }
                            // } else {
                            //     return {
                            //         ...target,
                            //         selected: false,
                            //         blocked: cat.name.toUpperCase() === multiSelect.toUpperCase() ? false : true
                            //     }
                            // }

                        })
                    }
                })
            }
        })
    } : {});
    let [checkChanged, setCheckChanged] = useState(false);
    let getSelectedItems = () => {
        let arr = [];
        checkUrl !== null && element.sub_menus.map(menu => {

            menu.menu_bistronomique_category.map(cat => {

                cat.menu_bistronomique.map(target => {
                    targetOrder.items.map(item => {
                        if (item.name === target.title) {
                            arr.push(target);
                        }
                    })

                })

            })

        })

        return arr;
    }
    let selectedItems = getSelectedItems();
    let checkContains = (arr, item) => {
        let contains = false;
        arr.map(ele => {
            if (ele.title === item.title) {
                contains = true;
            }
        })
        return contains;
    }
    let zeroState = () => {
        let temp = {
            ...elem, sub_menus: elem.sub_menus.map(menu => {
                return {
                    ...menu, menu_bistronomique_category: menu.menu_bistronomique_category.map(cat => {
                        return {
                            ...cat,
                            menu_bistronomique: cat.menu_bistronomique.map(target => {
                                return {
                                    ...target,
                                    selected: false,
                                    blocked: false
                                }

                            })
                        }
                    })
                }
            })
        }
        setElement({ ...temp })
    }
    let activeMenu = -1;
    if (menuType === 2) {
        activeMenu = element.sub_menus.findIndex(item => parseInt(item.name.charAt(item.name.length - 1)) === targetOrder.type
        );
    }

    /** Menu 2 Services */

    let chooseItem = (mainId, subId, item) => {
        let mainTar = element.sub_menus.filter(tarMen => {
            return tarMen.id === mainId;
        })
        let mainIndex = element.sub_menus.indexOf(mainTar[0]);
        let catTar = element.sub_menus[mainIndex].menu_bistronomique_category.filter(cat => {
            return cat.id === subId;
        })
        let catIndex = element.sub_menus[mainIndex].menu_bistronomique_category.indexOf(catTar[0]);
        const categ = element.sub_menus[mainIndex].menu_bistronomique_category[catIndex].name.toUpperCase();
        if ((((!item.selected) && (!item.blocked)) || (item.blocked)) && (categ !== multiSelect.toUpperCase())) {
            let shallowItem = { ...element };
            shallowItem.sub_menus[mainIndex].menu_bistronomique_category[catIndex].menu_bistronomique = shallowItem.sub_menus[mainIndex].menu_bistronomique_category[catIndex].menu_bistronomique.map(temp => {
                if (temp.id === item.id && temp.title === item.title) {
                    return {
                        ...temp,
                        selected: true,
                        blocked: false
                    }
                } else {
                    return {
                        ...temp,
                        selected: false,
                        blocked: true
                    }
                }

            })
            setElement(shallowItem);
        } else if (categ === multiSelect.toUpperCase()) { // Dessert so I can select more than one choice
            let shallowItem = { ...element };
            if (!item.selected) { // If item not selected select it
                let tempElem = {
                    ...shallowItem.sub_menus[mainIndex].menu_bistronomique_category[catIndex].menu_bistronomique[shallowItem.sub_menus[mainIndex].menu_bistronomique_category[catIndex].menu_bistronomique.indexOf(item)],
                    selected: true,
                    blocked: false
                };
                shallowItem.sub_menus[mainIndex].menu_bistronomique_category[catIndex].menu_bistronomique[shallowItem.sub_menus[mainIndex].menu_bistronomique_category[catIndex].menu_bistronomique.indexOf(item)] = tempElem;
                // Choice a is choosen
                if (shallowItem.sub_menus[mainIndex].menu_bistronomique_category[catIndex].menu_bistronomique.indexOf(tempElem) === 0) {
                    // Choice b is also selected so add 14 to the price
                    if (shallowItem.sub_menus[mainIndex].menu_bistronomique_category[catIndex].menu_bistronomique[1].selected === true) {
                        
                        setMenuPrice(price + 14);

                    } else { // Choice b is not selected
                        
                        setMenuPrice(price);

                    }
                }
                // Choice b is choosen
                if (shallowItem.sub_menus[mainIndex].menu_bistronomique_category[catIndex].menu_bistronomique.indexOf(tempElem) === 1) {
                    // Choice a is also selected so add 14 to the price
                    if (shallowItem.sub_menus[mainIndex].menu_bistronomique_category[catIndex].menu_bistronomique[0].selected === true) {
                       
                        setMenuPrice(price + 14);

                    } else { // Choice a is not selected so add 4 to the price
                        
                        setMenuPrice(price + 4);

                    }
                }
            } else { // If item selected unselect it
                let tempElem = {
                    ...shallowItem.sub_menus[mainIndex].menu_bistronomique_category[catIndex].menu_bistronomique[shallowItem.sub_menus[mainIndex].menu_bistronomique_category[catIndex].menu_bistronomique.indexOf(item)],
                    selected: false,
                    blocked: false
                };
                shallowItem.sub_menus[mainIndex].menu_bistronomique_category[catIndex].menu_bistronomique[shallowItem.sub_menus[mainIndex].menu_bistronomique_category[catIndex].menu_bistronomique.indexOf(item)] = tempElem;
                // Choice b is not choosen
                if (shallowItem.sub_menus[mainIndex].menu_bistronomique_category[catIndex].menu_bistronomique.indexOf(tempElem) === 1) {
                    setMenuPrice(price);
                }
                // Choice a is not choosen
                else if (shallowItem.sub_menus[mainIndex].menu_bistronomique_category[catIndex].menu_bistronomique.indexOf(tempElem) === 0) {
                    // Choice b is choosen
                    if (shallowItem.sub_menus[mainIndex].menu_bistronomique_category[catIndex].menu_bistronomique[1].selected) {
                        setMenuPrice(price + 4);
                    }
                    else {
                        setMenuPrice(price);
                    }
                }
            }
            setElement(shallowItem);
        }
    }
    let checkSelected = (item) => {
        return item.selected;
    }
    let checkBlocked = (item) => {
        return item.blocked;
    }

    let beforeArr = [];
    let afterArr = [];

    if (menuType === 2) {
        element.sub_menus.map(menu => {
            menu.menu_bistronomique_category.map(cat => {
                beforeArr.push(cat);
            })
        })

        beforeArr.map(ele1 => {
            beforeArr.map(ele2 => {
                if ((beforeArr.indexOf(ele1) !== beforeArr.indexOf(ele2)) && (ele1.id === ele2.id)) {
                    afterArr = [...beforeArr.splice(beforeArr.indexOf(ele2), 1)];
                } else {
                    afterArr = [...beforeArr];
                }
            })
        })
    }

    let changeOrder = () => {

        let targetIndex = activeOrder.orders.findIndex(item => {
            return item.randId === targetOrder.randId;
        });
        var tempOrder = {
            ...targetOrder,
            items: []
        };
    
        element.sub_menus.map(menu => {
            menu.menu_bistronomique_category.map(menuCat => {
                menuCat.menu_bistronomique.map(elem => {
                    if (elem.selected === true) {
                       
                        tempOrder.items.push({
                            id: elem.id,
                            categoryName: menuCat.name,
                            name: elem.title,
                            count: 1
                        });

                    }

                })
            })
        })
        if (targetIndex !== -1) {
            let shallowOrders = [...activeOrder.orders];
            shallowOrders[targetIndex] = {
                ...tempOrder,
                items: tempOrder.items,
                price: menuPrice
            }
            let temp = {
                ...activeOrder,
                orders: [...shallowOrders]
            }
            handleChange(temp);
            // window.location.href = '/yourOrder';
        }
    }
    let removeOrder = () => {
        let targetIndex = activeOrder.orders.findIndex(item => {
            return item.randId === targetOrder.randId;
        });
        if (targetIndex !== -1) {
            let shallowOrders = [...activeOrder.orders];
            shallowOrders[targetIndex] = {
                ...targetOrder,
                removed: true
            }
            let temp = {
                ...activeOrder,
                orders: [...shallowOrders]
            }
            handleChange(temp);
        }
    }

    return (
        checkUrl !== null && <section className="main-section menu-section edit-menu-section">
            <Header title={element.name} target="/yourOrder" state={{}} />
            <div className="main-container">

                {   // Set Button Selected
                    menuType === 2 ? <div className="select-area">
                        <h2>{targetOrder.optionText}</h2>
                    </div> : ''
                }
                {
                    (menuType === 2) && (
                        element.sub_menus[activeMenu].menu_bistronomique_category.map(item => (
                            <div className="non-selectable-services" key={item.id}>
                                <div className="non-selectable-service-name">{item.name.toUpperCase()}</div>
                                <div className="non-selectable-service-elems">
                                    {
                                        item.menu_bistronomique.map(sub => (
                                            <div key={sub.id} className={checkBlocked(sub) ? "non-selectable-service-elem-name blocked" : checkSelected(sub) ? "non-selectable-service-elem-name selected" :
                                                checkContains(selectedItems, sub) && checkChanged === false ? "non-selectable-service-elem-name selected" : "non-selectable-service-elem-name"
                                            } style={{ marginBottom: item.description && item.menu_bistronomique.indexOf(sub) === item.menu_bistronomique.length - 1 ? '0px' : '18px' }} onClick={() => {
                                                if (activeOrder.active) {
                                                    chooseItem(element.sub_menus[activeMenu].id, item.id, sub);
                                                    if (checkChanged === false) {
                                                        setMenuPrice(targetOrder.mainPrice);
                                                        setCheckChanged(true);
                                                    }
                                                }
                                            }
                                            }>
                                                <div className="non-selectable-service-elem-EnnerName">{sub.title}</div></div>
                                        ))}
                                    <p className="description">
                                        {item.description}
                                    </p>
                                </div>

                            </div>
                        ))
                    )
                }
                {(menuType === 3) && (
                    element.sub_menus.map(menu => (
                        menu.menu_bistronomique_category.map(item => (
                            <div className="non-selectable-services" key={item.id}>
                                <div className="non-selectable-service-name">{item.name.toUpperCase()}</div>
                                <div className="non-selectable-service-elems">
                                    {
                                        item.menu_bistronomique.map(sub => (
                                            <div key={sub.id} className={checkBlocked(sub) ? "non-selectable-service-elem-name blocked" : checkSelected(sub) ? "non-selectable-service-elem-name selected" :
                                                checkContains(selectedItems, sub) && checkChanged === false ? "non-selectable-service-elem-name selected" : "non-selectable-service-elem-name"
                                            } style={{ marginBottom: item.description && item.menu_bistronomique.indexOf(sub) === item.menu_bistronomique.length - 1 ? '0px' : '18px' }} onClick={() => {
                                                if (activeOrder.active) {
                                                    chooseItem(menu.id, item.id, sub);
                                                    if (checkChanged === false) {
                                                        setMenuPrice(targetOrder.mainPrice);
                                                        setCheckChanged(true);
                                                    }
                                                }

                                            }}>
                                                <div className="non-selectable-service-elem-EnnerName">{sub.title}</div></div>
                                        ))}
                                    <p className="description">
                                        {item.description}
                                    </p>
                                </div>

                            </div>
                        ))
                    ))
                )

                }
                {(menuType === 3 || (menuType === 2)) && (
                    <div className="edit-area-for-bootle">
                        <Link to={`/yourOrder`} className='edit-icon-plus' onClick={() => activeOrder.active && removeOrder()} state={{}}>
                            <EditIconPlus />
                        </Link>
                        <Link to={'/yourOrder'} className="buy-button-without" onClick={() => activeOrder.active && changeOrder()} state={{}}>
                            <div className="buy-button-without-text">Ajouter  1</div>
                            <div className="space">  </div>
                            <div className="buy-button-without-price">(€{menuPrice})</div></Link></div>
                )
                }
            </div>
        </section >
    )
}
export default MenuEdit;