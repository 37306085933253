import React, { useContext } from 'react';
import { OrderContext } from '../../contexts/OrderContext';
import { Bottle, Glass } from '../Svgs/Svgs';
import './MenuItem.css';
import { Cart } from '../Svgs/Svgs';
import { Link } from 'react-router-dom';
import $ from 'jquery';
const MenuItem = (props) => {
    const { activeOrder } = useContext(OrderContext);
    // Check the type of menu service
    const menuTypeService = props.elem.sub_menus.length === 1 ? 3 : 2;
    return (
        <div className="menu-item">
            {
                props.elem.name ? (
                    <div className="menu-item-header menu-item-row" style={{ border: 'none' }}>
                        <Link className="menu-item-name" to={`/menuService/${props.id}/${props.elem.id}`} state={{ menuType: menuTypeService, elem: props.elem, price: props.elem.price }}>
                            <div className="menu-item-text">{props.elem.name}</div>
                            <div className="menu-item-price">{props.elem.price}</div>
                        </Link>
                        <div className="menu-item-description">
                            {
                                menuTypeService === 3 && (
                                    props.elem.sub_menus[0].menu_bistronomique_category.map(element => (
                                        <span key={element.id}>{element.name.toLowerCase()} {props.elem.sub_menus[0].menu_bistronomique_category.length - 1 !== props.elem.sub_menus[0].menu_bistronomique_category.indexOf(element) ? ' , ' : ''}</span>
                                    )))


                            }
                            {
                                menuTypeService === 2 && (
                                    props.elem.sub_menus.map(menu => (
                                        <>
                                            {menu.menu_bistronomique_category.map(element1 => (
                                                <span key={element1.id}>{element1.name.toLowerCase()} {menu.menu_bistronomique_category.length - 1 !== menu.menu_bistronomique_category.indexOf(element1) ? ' et ' : ''}</span>
                                            ))}
                                            <span key={menu.id}>{props.elem.sub_menus.length - 1 !== props.elem.sub_menus.indexOf(menu) ? ' ou ' : ''}</span></>
                                    ))

                                )
                            }
                        </div>
                    </div>
                ) : ''
            }
        </div>
    )
}
export default MenuItem;