import React, { useState } from "react";
import $ from 'jquery';
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
import { useLocation } from "react-router-dom";
// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
import './events.css';
// import required modules
import { Navigation } from "swiper";
import Img1 from '../../images/event-1.jpg';
import Img2 from '../../images/event-2.jpg';
import Img3 from '../../images/event-3.jpg';
import Img4 from '../../images/event-4.jpg';
import Header from "../Header/Header";
import Event from "../Event/Event";
const Events = (props) => {
    const data = useLocation().state.data;
    const [events, setEvents] = useState([
        {
            id: 0,
            subDesc: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua',
            mainDesc: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur",
            img: Img1,
            infos: {
                main: 'Name event',
                sub: '15/7/2022'
            }
        },
        {
            id: 1,
            subDesc: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua',
            mainDesc: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur",
            img: Img2,
            infos: {
                main: 'Name event',
                sub: '15/7/2022'
            }
        },
        {
            id: 2,
            subDesc: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris.',
            mainDesc: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur",
            img: Img3,
            infos: {
                main: 'Name event',
                sub: '15/7/2022'
            }
        },
        {
            id: 3,
            subDesc: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua',
            mainDesc: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur",
            img: Img4,
            infos: {
                main: 'Name event',
                sub: '15/7/2022'
            }
        }
    ])
    $(function () {

        $('.events-section .event').on('mouseover', function () {
            $(this).addClass('choosen').removeClass('not-choosen');
            $(this).parent().siblings().find('.event').addClass('not-choosen').removeClass('choosen');
        });
        $('.events-section .event').on('mouseleave', function () {
            $(this).removeClass('choosen').removeClass('not-choosen');
            $(this).parent().siblings().find('.event').removeClass('not-choosen').removeClass('choosen');
        });

    });
    window.scrollTo(0, 0);
    return (
        <section className="main-section events-section">
            <Header title='Nos événements' target="/" />
            <div className="main-container">
                <Swiper
                    slidesPerView={3}
                    spaceBetween={0}
                    speed={1500}
                    allowTouchMove={true}
                    navigation={
                        {
                            nextEl: '.review-swiper-button-next',
                            prevEl: '.review-swiper-button-prev',
                        }}
                    breakpoints={{
                        1: {
                            direction: 'vertical',
                            slidesPerView: 'auto',
                            allowTouchMove: false
                        },
                        577: {
                            direction: 'horizontal',
                            slidesPerView: 1,
                            allowTouchMove: true
                        },
                        1025: {
                            slidesPerView: 3,
                            direction: 'horizontal'
                        }

                    }}
                    modules={[Navigation]}
                    className="events-swiper"
                >
                    <div className="review-swiper-button-prev ">
                        <div className="first-arr">
                            <span></span>
                            <span></span>
                        </div>
                        <div className="second-arr">
                            <span></span>
                            <span></span>
                        </div>
                    </div>
                    <div className="review-swiper-button-next ">
                        <div className="first-arr">
                            <span></span>
                            <span></span>
                        </div>
                        <div className="second-arr">
                            <span></span>
                            <span></span>
                        </div>
                    </div>
                    {
                        data.map(event => {
                            return (
                                <SwiperSlide key={event.id}>
                                    <Event event={event} />
                                </SwiperSlide>
                            )
                        })
                    }
                    <div className="review-swiper-button-prev center-con">

                        <div className="round">
                            <span></span>
                            <span></span>
                            <span></span>
                            <span></span>
                        </div>

                    </div>
                    <div className="review-swiper-button-next center-con">

                        <div className="round">
                            <span></span>
                            <span></span>
                            <span></span>
                            <span></span>
                        </div>

                    </div>
                </Swiper>
            </div>
        </section >
    )

}
export default Events;