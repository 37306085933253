import React, { useState, useEffect, useContext } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useGeolocated } from "react-geolocated";
import axios, { Axios } from 'axios';
import './order.css';
import { OrderContext } from '../../contexts/OrderContext';
import Header from '../Header/Header';
import MenuElement from '../MenuElement/MenuElement';
import MenuItem from '../MenuItem/MenuItem';
import ServiceItem from '../ServiceItem/ServiceItem';
import OrderBtn from '../OrderBtn/OrderBtn';
import { Bottle, EmptyGlass, EditIcon } from '../Svgs/Svgs';
import Cookies from 'js-cookie';
import Loading from '../Loading/Loading';
const Order = (props) => {
    const [mainElems, setMainElems] = useState([]);
    const { activeOrder, handleChange } = useContext(OrderContext);
    const checkUrl = useLocation().state;
    useEffect(() => {
        if (checkUrl === null) {
            window.location.href = '/directaccess';
        }
        console.log(activeOrder.orders);
    }, []);
    const [loading, setLoading] = useState(false);
    let ordersPrice = 0;
    activeOrder.orders.map(elem => {
        if (!elem.removed) { ordersPrice += elem.price; }
    });

    window.scrollTo(0, 0);
    return (
        checkUrl !== null && <section className="main-section menu-section">
            {loading && <Loading />}
            <Header title={'Votre Commande'} target="/cart" state={{}} />
            <div className="main-container main-container-your-order">
                <div className="cart-orders">
                    {
                        activeOrder.orders.map(order => {
                            
                            var isBottle = order.hasOwnProperty('bottleCount');
                            // Check if it is service menu or not
                            return order.hasOwnProperty('type') ? (
                                <div key={order.randId}>
                                    <div className="order-side">
                                        <Link to={`/menuedit`} style={order.removed ? { visibility: 'hidden' } : {}} state={{ order: order }}>
                                            <EditIcon />
                                        </Link>
                                        <div className="order-name-cart" style={order.removed ? { 'text-decoration': 'line-through' } : {}} >{order.menuName}
                                            <div className="service-services-cart">{order.hasOwnProperty('items') && order.items.map(item => {
                                                return (<div className="service-services-cart-area" key={item.id}>
                                                    <div className="a-small-margin" style={order.removed ? { 'text-decoration': 'line-through' } : {}} >- </div><div>{item.name}</div>
                                                </div>)
                                            })}</div></div>
                                        <div className="order-quantity-cart" style={order.removed ? { 'text-decoration': 'line-through' } : {}} >(1)</div>
                                        <div className="order-price-cart" style={order.removed ? { 'text-decoration': 'line-through' } : {}} >{order.price}</div>
                                    </div>

                                    <div className='line-between-orders'></div>
                                </div>
                            ) : (
                                <div key={order.randId}>
                                    <div className="order-side">
                                        <Link to={order.hasOwnProperty('bottleCount') ? `/bottle/edit/0/0/0` : `/glass/edit/0/0/0`} style={order.removed ? { visibility: 'hidden' } : {}} state={{ order: order }}>
                                            <EditIcon />
                                        </Link>
                                        <div style={order.removed ? { 'text-decoration': 'line-through' } : {}} className="order-name-cart">{order.elemName}</div>
                                        <div style={order.removed ? { 'text-decoration': 'line-through' } : {}} className="order-quantity-cart">({isBottle ? order.bottleCount : order.glassCount})</div>
                                        <div style={order.removed ? { 'text-decoration': 'line-through' } : {}} className="order-price-cart">{order.price}</div>
                                    </div>
                                    {isBottle && (<div className="order-quantity-number-side" style={order.removed ? { visibility: 'hidden' } : {}}>
                                        <div className="order-quantity-number-side-text">{order.glassCount}</div> <EmptyGlass />
                                    </div>)}
                                    <div className='line-between-orders'></div>
                                </div>
                            )
                        })
                    }
                </div>
            </div>

            {/* {
                        orders.map(order => {
                            return (<div>
                                <div className="order-side">
                                    <Link to={`/bottle/edit/0/0/0`} style={order.is_deleted ? { visibility: 'hidden' } : {}}>
                                        <EditIcon />
                                    </Link>
                                    <div style={order.is_deleted ? { 'text-decoration': 'line-through' } : {}} className="order-name-cart">{order.name}</div>
                                    <div style={order.is_deleted ? { 'text-decoration': 'line-through' } : {}} className="order-quantity-cart">({order.quantity})</div>
                                    <div style={order.is_deleted ? { 'text-decoration': 'line-through' } : {}} className="order-price-cart">{order.quantity * order.price.one}</div>
                                </div>
                                <div className="order-quantity-number-side" style={order.is_deleted ? { visibility: 'hidden' } : {}}>
                                    <div className="order-quantity-number-side-text">{order.quantity}</div> <EmptyGlass />
                                </div>
                                <div className='line-between-orders'></div>
                            </div>)
                        })
                    } */}
            {/* {
                        services.map(service => {
                            return (<div>
                                <div className="order-side">
                                    <EditIcon />
                                    <div className="order-name-cart">{service.name}
                                        <div className="service-services-cart">{service.services.map(one => {
                                            return (<div className="service-services-cart-area">
                                                <div className="a-small-margin">- </div><div>{one.name}</div>
                                            </div>)
                                        })}</div></div>
                                    <div className="order-quantity-cart">(1)</div>
                                    <div className="order-price-cart">{service.price[0]}</div>
                                </div>

                                <div className='line-between-orders'></div>
                            </div>)
                        })
                    } */}


            <Link to={activeOrder.locate ? '/finalsave' : '/save'} className="buy-button-without" state={{}}>
                <div className="buy-button-without-text">Confirmer</div>
                <div className="space">  </div>
                <div className="buy-button-without-price">(€ {ordersPrice})</div>
            </Link>

        </section >
    )
}
export default Order;